@charset "UTF-8";
.meeting-list-wrapper {
  padding: 20px 30px;
}
.meeting-list-wrapper .search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.meeting-list-wrapper .search-form .tb-form-item-search {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-right: 20px;
      margin-bottom: 20px;
}
.meeting-list-wrapper .search-form .tb-form-item-search .el-form-item__label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #303133;
}
.meeting-list-wrapper .meeting-list-body {
    height: 100%;
    min-height: 400px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card {
        width: 49%;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        margin-bottom: 30px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group {
          background: -webkit-gradient(linear, left top, right top, from(#dbe9ff), to(#f5f9ff));
          background: linear-gradient(90deg, #dbe9ff, #f5f9ff);
          padding: 10px 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          line-height: 1;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-group-left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            line-height: 1;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #303133;
            line-height: 1;
            max-width: 400px;
            overflow: hidden;
            white-space: nowrap;
            /* 不换行 */
            text-overflow: ellipsis;
            /* 显示省略号 */
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-tag {
            font-size: 14px;
            line-height: 1;
            padding: 0px 15px;
            height: 24px;
            line-height: 24px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border-radius: 12px;
            margin-left: 10px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-state-tag {
            border: 1px solid #409eff;
            color: #409eff;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-type-tag {
            border: 1px solid #8894f2;
            color: #8894f2;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-hd-group .hd-subject-tag {
            border: 1px solid #ffa112;
            color: #ffa112;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group {
          padding: 10px 30px 30px 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-top: 10px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left .bd-ft-process {
              width: 90px;
              height: 90px;
              position: relative;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left .bd-ft-process .bd-ft-process-count {
                position: absolute;
                top: 38%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 1;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left .bd-ft-process .bd-ft-process-state {
                position: absolute;
                top: 62%;
                left: 50%;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #606266;
                line-height: 1;
                -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left .bd-ft-process .finish-count {
                color: #409eff;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-left .bd-ft-process .unfinished-count {
                color: #ff5959;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            padding-left: 30px;
            margin-top: 20px;
            line-height: 1;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell .row-cell-title {
              color: #909399;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell .row-cell-title span {
                display: block;
                width: 70px;
                -moz-text-align-last: justify;
                     text-align-last: justify;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell .row-cell-desc {
              color: #303133;
              font-weight: 400;
              -webkit-box-flex: 1;
                  -ms-flex: 1;
                      flex: 1;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              /* 不换行 */
              text-overflow: ellipsis;
              /* 显示省略号 */
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell .row-cell-time {
              font-weight: bold;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .row-cell .el-tooltip {
              display: block;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-mid {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
            overflow: hidden;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-mid .row-cell-title span {
              width: 60px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-right {
            width: 250px;
            overflow: hidden;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-body-group .bd-right .row-cell-title span {
              width: 70px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-footer-group {
          padding: 10px 45px;
          border-top: 1px solid #e6e6e6;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          width: 100%;
          -webkit-box-pack: end;
              -ms-flex-pack: end;
                  justify-content: flex-end;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card .c-footer-group .el-button {
            height: 36px;
            padding: 0 25px;
}
.meeting-list-wrapper .meeting-list-body .meeting-list-main .m-card:hover {
        -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
                box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
}
.meeting-list-wrapper .meeting-list-body .center-pagination {
      margin: 0px 10px 20px 10px;
      text-align: center;
      width: 100%;
}
.common-tip-popper {
  max-width: 80%;
}
